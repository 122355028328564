<template>
    <div class="kd-hobby">
        <div class="hobby-content">
            <h1 class="hobby-title">{{content.title}}</h1>
            <div class="hobby-text" v-html="content.content"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return{
            content: {}
        }
    },
    mounted() {
        this.getContent()
    },
    methods: {
        getContent() {
          this.$api.getHobbyCertificate().then(result=>{
            let res = result.data;
            if(res.code == 200){
                this.content = res.data;
            }
          }).catch(error=>{})
        }
    }
}
</script>
<style lang="less" scoped>
  .kd-hobby{
      background: #f4f5f6;
  }
  
  .hobby-content{
      background: white;
      .hobby-title{
        margin-top: 20px;
        color: #fe5225;
        font-size: 26px;
      }
      .hobby-text{
          padding: 20px 0;
          line-height: 35px;
          font-size: 17px;
          color: #333333;
      }
  }
</style>