<template>
  <div class="guide-content" v-html="tips.content"></div>
</template>
<script>
export default {
  data() {
    return {
      tips: {
        content: '<p>1.进入APP【我的】，点击【设置】；</p><p>2.点击“账号管理”，选择“手机绑定”/“邮箱绑定”，后即可重置密码；</p><p>3.若没绑定手机号/邮箱要重置密码，请拨打客服电话：18802009181/18802000173；</p>',
        title: '没绑定手机号/邮箱要重置密码？'
      }
    }
  },
  async mounted() {
    let { data: res } = await this.$api.loginGuideText();
    if(res.code === 200) {
      this.tips.content = res.data.content || this.tips.content;
      document.title = res.data.title || this.tips.title;
    }
  }
}
</script>
<style lang="less" scoped>
  .guide-content{
    padding: 20px;
    line-height: 24px;
    font-size: 16px;
  }
</style>