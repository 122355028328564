<template>
  <div class="about">
    <h1 class="title">关于凯迪</h1>
    <p><br /></p>
    <p></p>
    <p>凯迪网络是2000年初发布的以社区为主体的自媒体，注册地在海南省海口市，是中央网信办重点管理的全国十三家重点商业网媒之一，也是全国仅有的三家以电子公告栏（BBS）形式存在的民营综合自媒体网络平台。</p>
    <p><br /></p>
    <p>
      二十年来，在中央及省市宣传部、网信办的大力支持和凯迪人的努力下，凯迪网络在全球范围内的影响力不断扩大。目前，已拥有1458万注册用户，最高同时在线人数突破70万人，日最高发帖量75754，日最高PV4000多万，其中移动端占比50%左右。凯迪的用户以公务员、企事业高管、知识分子等中高端网民为主，访问人群年龄从18岁至80多岁不等，其中以25岁至55岁的网友为主流。在过去三个月里，访问凯迪网络的国内用户占了98.36%，访问量前十的地区依次是广东省（25.84%）、北京市（22.67%）、上海市（5.68%）、浙江省（5.44%）、江苏省（4.97%）、四川省（3.66%）、山东省（3.18%）、湖北省（2.73%）、湖南省（2.23%）、河南省（2.15%）。可见，访问凯迪网络的用户主要来自于国内政经中心，“北上广”的用户接近55%。凯迪网络的境外访问量虽然只占到总访问量的1.64%，但在同类型网站中是非常高的，其中美国占比最高，为0.63%，其它依次为加拿大、港澳台地区、澳大利亚和日本等等，凯迪网络因此成为国内重要的外宣平台，也成为外媒观察中国、了解中国的重要窗口。
      作为全球知名华人网络互动平台，凯迪网络的关注视角从未在国际、国内任何大事件中缺位，在重大的舆论监督场合，凯迪坚持
      “建设者”的立场，始终站在信息最前沿，成为最具代表性的民意表达平台。各栏目的流量中，以《猫眼看人》为最，在全球中文同类论坛中屡屡高居第一，其次为经济、文化艺术、娱乐、消费信息等板块。
      凯迪网络以热点追踪、深度评论见长，更以精致化信息服务获得了精英阶层的青睐，其用户忠诚度在同类型媒体中名列前茅。在微博、微信兴起的新型互联网时代，作为自媒体的凯迪社区已成为移动端平台的信息来源地之一，弥补了微博微信无信息数据库支撑的缺憾。
    </p>
    <p><br /></p>
    <p>
      二十年来，凯迪网络已建起一整套严格、精细化管理的规章制度，培养了一支扎实、可靠的编辑队伍。经不断发展完善，凯迪网络已发展为国内外均具有较强影响力、传播力的华文网络互动传媒。
      进入2020年，为了适应日趋激烈的市场竞争格局，凯迪网络决意启动全新战略部署：在原有凯迪社区的基础上，新增实时综合资讯服务，专注于要闻、财经等内容，并结合智能算法技术，为互联网及移动互联网用户群体打造一个极具个性化的资讯及互动平台。
      与此同时，基于凯迪网络的商业布局，整合新增了包括云商会、凯迪游戏、海南自贸港在内的特色板块，为B2B的商业合作提供全新的合作平台。
      此外，凯迪网络也积极鼓励更多不同领域的原创作者参与内容创作。因此，在2020年底，开发上线了属于凯迪网络的原创内容平台——凯迪号，为原创内容的价值赋能。
      凯迪网络，在过去的二十年里，前行从未停歇！在下一个二十年里，将继续书写属于凯迪网络价值观的辉煌印记。
    </p>
    <p><br /></p>
    <p></p>

    <h2 style="color: #fe5225;font-size: 26px">企业文化</h2><br>
    <p>企业使命：传递正能量内容，为客户创作价值，提升用户生活品质</p>
    <p>企业愿景：做全球领先的内容创作与分享交流平台</p>
    <p>企业价值观：客户为先 合作共赢 正直诚信 成果驱动 创新高效</p><br>
    <h3 style="color: #fe5225;font-size: 18px">经营理念</h3><br>
    <p>诚信为本：诚信是商业合作的立足之本，也是企业持续发展和增强市场竞争力的核心力量。凯迪网络在发展上秉承诚信为本的理念，不仅自身要做诚信企业，而且在合作的过程中选择诚信的供应商、投资方，共同遵守行业诚信体系。</p>
    <p>服务至上：在当今竞争激烈的市场环境中，凯迪网络保持“服务至上”的理念，留意市场、受众用户需求爱好的变动，让企业适应用户需求的变化，提高自身市场竞争力的同时，以专业的服务为用户带来能满足他们需求的产品。</p>
    <p>追求创新：自成立以来，凯迪网络深知创新是一个企业不竭的生命力，在发展中保持“追求创新”的精神，增加自身的业务创新能力，以此推动企业继续前进发展。</p>
    <p>互利共赢：凯迪网络以 诚信、专业、创新、共赢为企业宗旨，恪守互利共赢的合作观。“互利”代表了凯迪网络在合作关系中持积极开放的态度，而“共赢”则代表了凯迪网络以彼此携手构建新发展格局、实现共赢发展为双方合作的最终目标。</p>
    <br><h3 style="color: #fe5225;font-size: 18px">发展理念</h3><br>
    <p>员工是企业发展的核心源头，凯迪网络自成立以来秉承“以人为本”的发展理念，感恩组织内每一位员工所做出的贡献，注重用人、育人和留人，让员工与企业共同成长，一起打造互联网商业生态圈。</p><br>

    <h2 class="title">关于凯迪号</h2>
    <p><br /></p>
    <p></p>
    <p>
      凯迪号，是凯迪网络旗下开放的内容创作与分发平台，是实现政府部门、媒体、企业、个人、以及其他组织等内容创作者与用户之间的智能链接，致力于帮助内容创作者在移动端获得更多曝光和关注，同时实现品牌传播、内容变现和商业收益。
    </p>
    <p><br /></p>
    <p></p>
    <h3 class="title">平台功能</h3>
    <p><br /></p>
    <p></p>
    <p><b>内容发布 ：</b></p>
    <p>凯迪号支持内容创作者轻松发布多样化形式的内容，包括文章和帖子等等，个人创作者入驻平台后，可以在后台选择以上其中一种形式进行编辑创作。</p>
    <p><b>内容二次编辑 ：</b></p>
    <p>个人创作者可对自己以往发布的文章、帖子进行二次编辑。</p>
    <p><b>创作收益 ：</b></p>
    <p>个人创作者在凯迪号发布文章可以获得创作收益。</p>
    <p><b>内容获赏 ：</b></p>
    <p>读者可对个人创作者发布的文章和帖子进行一定金额的赞赏，所得收益扣税后归个人创作者所有。</p>
    <p><br /></p>
    <p></p>
    <h3 class="title">用户成长体系</h3>
    <p><br /></p>
    <p></p>
    <p>通过凯迪号平台审核认证后的用户将会进入猫眼成长体系。平台为个人创作者构建了一个完善的成长体系，该体系是衡量个人创作者创作力的重要指标。</p>
    <p>用户等级由粉丝量决定，当粉丝量达到相应的指标，用户等级将自动升级，用户成长等级主要分为白猫、黑猫、红猫、紫猫、金猫。</p>
    <p><br /></p>
    <p class="footer"></p>
<!--    <h2 class="title">公司管理层变更如下：</h2>
    <p><br /></p>
    <p></p>
    <p>总裁：李希</p>
    <p>常务副总裁：黄铧</p>
    <p>副总裁：肖勃、段昱宽</p>
    <p>助理副总裁：张宇（兼运营总监）</p>
    <p class="footer">助理副总裁：唐金武（兼内容总编）</p>-->
  </div>
</template>

<script>
export default {
  methods: {},
}
</script>

<style scoped lang="less">
@theme-color: #fe5225;
@theme-size: 16px;
.about {
  padding: 15px;
  font-size: @theme-size;
  .title {
    color: @theme-color;
  }
  .footer{
      margin-bottom: 60px;
  }
}
</style>
